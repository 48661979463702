import axios from 'axios';

const API_KEY = process.env.GOOGLE_MAPS_API_KEY;
const API_BASE_URL ='https://eat-app-api-middleware-b2g9k.ondigitalocean.app';

export const ENDPOINTS = {
  PLACES: `${API_BASE_URL}/api/places`,
  DETAILS: `${API_BASE_URL}/api/details`,
  GEOCODE: `${API_BASE_URL}/api/geocode`,
  PHOTO: `${API_BASE_URL}/api/photo`,
  FAVORITES: `${API_BASE_URL}/api/favorites`
};

export const fetchPhoto = async (photoReference) => {
  try {
    if (!photoReference) {
      console.error('Photo reference is missing');
      return null;
    }

    const response = await axios.get(ENDPOINTS.PHOTO, {
      params: {
        photo_reference: photoReference,
        maxwidth: 400
      }
    });

    if (response.data && response.data.url) {
      return response.data.url;
    } else {
      console.error('Unexpected response format:', response.data);
      return null;
    }
  } catch (error) {
    console.error('Error fetching photo:', error);
    return null;
  }
};

export const fetchRandomPlace = async (userLocation, radius) => {
  try {
    console.log('Fetching random place with params:', { userLocation, radius });
    const response = await axios.get(ENDPOINTS.PLACES, {
      params: {
        lat: userLocation.lat,
        lng: userLocation.lng,
        radius,
        type: 'food',
        keyword: 'restaurant|cafe|bar|bakery', // Add more food-related keywords
        rankby: 'prominence',
      },
    });
    console.log('API response:', response.data)

    const places = response.data.results;
    const randomPlace = places[Math.floor(Math.random() * places.length)];

    if (randomPlace.photos && randomPlace.photos.length > 0){
        const photoReference = randomPlace.photos[0].photo_reference;
        randomPlace.photoURL = `${ENDPOINTS.PHOTO}?maxwidth=400&photo_reference=${photoReference}`;
       
       console.log('photo reference ' + photoReference)
    }
    
    console.log(randomPlace.photoURL)
    return randomPlace;
  } catch (error) {
    console.error('Error fetching places:', error.response ||error);
    throw error;
  }
};




export const fetchGalleryImages = async (placeId) => {
 
  try {
    console.log('fetching gallery images for place ID: ', placeId)
    console.log('using endpoint:', ENDPOINTS.DETAILS)

    if(!placeId){
      console.error('no place_id provided to fetchGalleryImages');
      return [];
    }

    //passing in the parms directly in the url. will have api parse the url to extract the required parms
    const url = `${ENDPOINTS.DETAILS}?place_id=${encodeURIComponent(placeId)}&fields=photos`;
    console.log('Request URL:', url);
    const response = await fetch(url);

    console.log('gallery images response:', response.data)
    const data = await response.json();
    if (data.result && data.result.photos) {
   return data.result.photos.map(photo => photo.photo_reference);
     
  }
    console.log('no photos found in the response')
    return [];
  } catch (error) {
    console.error('Error fetching gallery images:', error.response || error);
    throw error;
  }
};


export const geocodeAddress = async (address) => {
  // You'll need to implement this function using a geocoding service
  // For example, you could use the Google Maps Geocoding API
  // This is a placeholder implementation
  
  //const url = `${GEO_BASE_URL}?address=${encodeURIComponent(address)}&key=${API_KEY}&sensor=false&fulfillOnZeroResults=true`;
  console.log('address being sent ', address)
  try{
    const encodedAddress = encodeURIComponent(address)
    const response = await fetch(`${API_BASE_URL}/api/geocode?address=${encodedAddress}&sensor=false&fulfillOnZeroResults=true`);
    console.log('geocode response:', response.data)
    const data = await response.json();
    
  if (data.results && data.results.length > 0) {
    const { lat, lng } = data.results[0].geometry.location;
    return { 
      latitude: lat, 
      longitude: lng,
      formattedAddress: data.results[0].formatted_address
    };
  } else {
    // If no exact match, try to find a nearby location
    const encodedAddress = encodeURIComponent(address)
    const nearbyResponse =await fetch(`${API_BASE_URL}/geocode?address=${encodedAddress}`);
    console.log('geocode nearby response:', response.data)
    const nearbyData = await nearbyResponse.json();
    console.log('address being sent ', address)
    console.log('data: ', data)
    
    if (nearbyData.results && nearbyData.results.length > 0) {
      const { lat, lng } = nearbyData.results[0].geometry.location;
      return { 
        latitude: lat, 
        longitude: lng,
        formattedAddress: data.results[0].formatted_address
      };
    } else {
      throw new Error('Unable to geocode address or find nearby location');
    }
  }
} catch (error) {
  console.error('Error geocoding address:', error);
  throw error;
}

    

  
};



export const initializeAutocomplete = (inputElement, callback) => {
  if (!window.google) {
    console.error(' from initializeautocomplete api Google Maps JavaScript API not loaded');
    return;
  }

  const autocomplete = new window.google.maps.places.Autocomplete(inputElement, {
    types: ['address']
  });

  autocomplete.addListener('place_changed', () => {
    const place = autocomplete.getPlace();
    if (place.geometry) {
      const address = place.formatted_address;
      const location = {
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng()
      };
      callback(address, location);
    }
  });

  return autocomplete;
};



// export const addFavoritePlace = async (userId, placeId) => {
//   try {
//     const response = await axios.post(ENDPOINTS.FAVORITES, { userId, placeId });
//     return response.data;
//   } catch (error) {
//     console.error('Error adding favorite place:', error);
//     throw error;
//   }
// };

export const addFavoritePlace = async (userId, placeId, name, vicinity, photoReference, firebaseUrl) => {
  try {
    const response = await axios.post(ENDPOINTS.FAVORITES, { 
      userId, 
      placeId, 
      name, 
      vicinity, 
      photoReference,
      firebaseUrl
    });
    return response.data;
  } catch (error) {
    console.error('Error adding favorite place:', error);
    throw error;
  }
};

export const getUserFavoritePlaces = async (userId) => {
  try {
    const response = await axios.get(`${ENDPOINTS.FAVORITES}/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user favorite places:', error);
    throw error;
  }
};

export const removeFavoritePlace = async (userId, placeId) => {
  try {
    const response = await axios.delete(ENDPOINTS.FAVORITES, { data: { userId, placeId } });
    return response.data;
  } catch (error) {
    console.error('Error removing favorite place:', error);
    throw error;
  }
};
import React, { useEffect, useRef } from 'react';

const GoogleSignIn = ({ onSuccess, onFailure }) => {
  const googleButton = useRef(null);

  useEffect(() => {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: "935802341690-rrnem4guafttgj31997fp1unrqa0jocm.apps.googleusercontent.com", // Replace with your Google Client ID
        callback: handleCredentialResponse
      });
      window.google.accounts.id.renderButton(
        googleButton.current, 
        { theme: "outline", size: "large" }
      );
    }
  }, []);

  const handleCredentialResponse = (response) => {
    if (response.credential) {
      // Decode the credential to get user information
      const decoded = JSON.parse(atob(response.credential.split('.')[1]));
      onSuccess(decoded);
    } else {
      onFailure('No credential received');
    }
  };

  return <div ref={googleButton}></div>;
};

export default GoogleSignIn;
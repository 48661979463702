import React from 'react';
import './ImagePlaceholder.css';

const Placeholder = ({ width, height, className }) => (
  <div 
    className={`image-placeholder ${className || ''}`}
    style={{
      width: width,
      height: height,
    }}
  >
    <div className="placeholder-animation"></div>
  </div>
);

export default Placeholder;
import React, { useRef, useEffect, useState } from 'react';
import './ButtonComponent.css';
import noImagePlaceholder from './images/no-image-placeholder.png';

const FavoritesList = ({ favorites, onRemove, onClose, onNavigate , onSort  }) => {
    const modalRef = useRef();

    const [sortOrder, setSortOrder] = useState('default');

    const handleSortChange = (event) => {
      const newSortOrder = event.target.value;
      setSortOrder(newSortOrder);
      onSort(newSortOrder);
  };

    
    const formatDate = (dateString) => {
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleNavigate = (place) => {
    console.log('Navigating to favorite:', place); // Debug log
    onNavigate(place);
};

    const uniqueFavorites = favorites.reduce((acc, current) => {
        const x = acc.find(item => item.place_id === current.place_id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

    useEffect(() => {
        console.log('Placeholder image path:', noImagePlaceholder);
        console.log('Favorites:', favorites);
      }, [favorites]);

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (modalRef.current && !modalRef.current.contains(event.target)) {
            onClose();
          }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [onClose]);

      console.log(noImagePlaceholder)

      const renderImage = (place) => {
        console.log('Rendering image for place:', place);
        const imageUrl = place.firebase_url || noImagePlaceholder;
        console.log('Image URL:', imageUrl);

      
    
        return (
          <img 
            src={imageUrl}
            alt={place.name || 'Favorite place'}
            className="favorite-image"
            onError={(e) => {
              console.error('Error loading image:', e);
              e.target.onerror = null;
              e.target.src = noImagePlaceholder;
              console.log('Set placeholder image:', noImagePlaceholder);
            }}
          />
        );
      };


    return (
    <div className="modal-overlay">
    <div className="modal" ref={modalRef}>
      <div className="modal-content">
        <button onClick={onClose} className="close-button">&times;</button>
        <h2 className="modal-title">Favorites</h2>
        <div className="sort-container">
                        <label htmlFor="sort-select">Sort by: </label>
                        <select id="sort-select" value={sortOrder} onChange={handleSortChange}>
                            <option value="default">Default</option>
                            <option value="recent">Recently Added</option>
                        </select>
                    </div>
        {uniqueFavorites.length === 0 ? (
          <p>No favorites added yet.</p>
        ) : (
          <ul className="favorites-list">
            {uniqueFavorites.map((place) => (
              <li key={`${place.place_id}-${place.id}`} className="favorite-item">
               {renderImage(place)}
                <div className="favorite-info">
                  <h3>{place.name}</h3>
                  <p>{place.vicinity}</p>
                  <p className="favorite-date">Added on: {formatDate(place.created_at)}</p>
                </div>
                
                <div className="favorite-buttons">
                  <button 
                     onClick={() => handleNavigate(place)}
                    className="button-17 navigate-button"
                  >
                    Navigate
                  </button>
                  <button 
                    onClick={() => onRemove(place.place_id)}
                    className="button-17 remove-button"
                  >
                    Remove
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
    </div>
  );
};

export default FavoritesList;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

body, html, #root, .App {
  margin: 0;
  padding: 0;
  height: 100%;
}
@media (max-width: 600px) {
  .distanceButtons {
    flex-direction: column;
    align-items: stretch;
  }

  .button, .selectedButton {
    width: 100%;
    max-width: none;
  }
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
  /* background-color: #f0f0f0; */
}

.App h1 {
  padding: 20px;
  background-color: #f8f8f8;
  margin: 0;
}

/* You might need to adjust these styles based on your existing Header component styles */
.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 20px;
  background-color: #f8f8f8;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-name {
  margin-right: 10px;
}

.sign-in-button,
.sign-out-button,
.favorites-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-left: 10px;
}

.sign-in-button {
  background-color: #4285F4;
  color: white;
}



.favorites-button {
  background-color: black;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";;AAEA;EACE,SAAS;EACT,UAAU;EACV,YAAY;AACd;AACA;EACE;IACE,sBAAsB;IACtB,oBAAoB;EACtB;;EAEA;IACE,WAAW;IACX,eAAe;EACjB;AACF;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,kBAAkB;EAClB,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,SAAS;AACX;;AAEA,yFAAyF;AACzF;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;;;EAGE,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;;;AAIA;EACE,uBAAuB;EACvB,YAAY;AACd","sourcesContent":["\n\nbody, html, #root, .App {\n  margin: 0;\n  padding: 0;\n  height: 100%;\n}\n@media (max-width: 600px) {\n  .distanceButtons {\n    flex-direction: column;\n    align-items: stretch;\n  }\n\n  .button, .selectedButton {\n    width: 100%;\n    max-width: none;\n  }\n}\n\n.App {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n  text-align: center;\n  /* background-color: #f0f0f0; */\n}\n\n.App h1 {\n  padding: 20px;\n  background-color: #f8f8f8;\n  margin: 0;\n}\n\n/* You might need to adjust these styles based on your existing Header component styles */\n.header {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  padding: 10px 20px;\n  background-color: #f8f8f8;\n}\n\n.user-info {\n  display: flex;\n  align-items: center;\n}\n\n.user-image {\n  width: 30px;\n  height: 30px;\n  border-radius: 50%;\n  margin-right: 10px;\n}\n\n.user-name {\n  margin-right: 10px;\n}\n\n.sign-in-button,\n.sign-out-button,\n.favorites-button {\n  padding: 8px 16px;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  font-size: 14px;\n  margin-left: 10px;\n}\n\n.sign-in-button {\n  background-color: #4285F4;\n  color: white;\n}\n\n\n\n.favorites-button {\n  background-color: black;\n  color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from 'react';
//import GoogleSignIn from './GoogleSignIn';
import { auth } from './firebase';
import { signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail  } from 'firebase/auth';
import axios from 'axios';

const API_BASE_URL = 'https://eat-app-api-middleware-b2g9k.ondigitalocean.app';

const SignInModal = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [error, setError] = useState(null);
  const [resetPasswordMessage, setResetPasswordMessage] = useState('');
  const [isResetMode, setIsResetMode] = useState(false)


  const saveUserToDatabase = async (user) => {
    try {
      console.log('Attempting to save user:', user.uid);
      const response = await axios.post(`${API_BASE_URL}/api/user`, {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName || '',
        photoURL: user.photoURL || ''
      });
      console.log('User saved to database:', response.data);
    } catch (error) {
      console.error('Error saving user to database:', error);
      if (error.response) {
        console.error('Server responded with:', error.response.data);
      }
      throw error;
    }
  };

  const handleEmailSignIn = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      let userCredential;
      if (isSignUp) {
        userCredential = await createUserWithEmailAndPassword(auth, email, password);
      } else {
        userCredential = await signInWithEmailAndPassword(auth, email, password);
      }
      await saveUserToDatabase(userCredential.user);
      onClose();
    } catch (error) {
      console.error('Authentication error:', error);
      setError(error.message);
    }
  };

  // const handleGoogleSignInSuccess = (profileObj) => {
  //   setSignInError(null);
  //   onGoogleSignIn(profileObj);
  // };

  // const handleGoogleSignInFailure = (error) => {
  //   console.error('Google Sign-In failed:', error);
  //   setSignInError('Failed to sign in. Please try again.');
  // };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      await saveUserToDatabase(result.user);
      onClose();
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSignUp = () => {
    // Implement sign up functionality
    console.log('Sign up clicked');
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setError('Please enter your email address.');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setResetPasswordMessage('Password reset email sent. Please check your inbox.');
      setIsResetMode(false);
    } catch (error) {
      setError('Error sending password reset email. Please try again.');
    }
  };

  const toggleResetMode = () => {
    setIsResetMode(!isResetMode);
    setError(null);
    setResetPasswordMessage('');
  };



  return (
    <div style={styles.modal}>
      <div style={styles.modalContent}>
        <button onClick={onClose} style={styles.closeButton}>&times;</button>
        <h2 style={styles.modalTitle}>
        {isResetMode ? 'Reset Password' : (isSignUp ? 'Sign Up' : 'Sign In')}
        </h2>
        {/* <div style={styles.googleSignInContainer}>
          <GoogleSignIn
            onSuccess={handleGoogleSignInSuccess}
            onFailure={handleGoogleSignInFailure}
          />
        </div> */}
        
        <form onSubmit={isResetMode ? handleForgotPassword : handleEmailSignIn} style={styles.form}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={styles.input}
            required
          />
          {!isResetMode && (
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={styles.input}
              required
            />
          )}
          <button type="submit" style={styles.submitButton}>
          {isResetMode ? 'Send New Password' : (isSignUp ? 'Sign Up' : 'Sign In')}
          </button>
        </form>
        {!isResetMode && (
          <>
        <div style={styles.divider}>
          <span style={styles.dividerText}>or</span>
        </div>
        <button onClick={handleGoogleSignIn} style={styles.googleButton}>
          Sign in with Google
        </button>
        <p style={styles.switchText}>
          {isSignUp ? 'Already have an account?' : "Don't have an account?"}
          <button onClick={() => setIsSignUp(!isSignUp)} style={styles.switchButton}>
            {isSignUp ? 'Sign In' : 'Sign Up'}
          </button>
        </p>
        </>)}
        <button onClick={toggleResetMode} style={styles.forgotPasswordButton}>
          {isResetMode ? 'Back to Sign In' : 'Forgot Password?'}
        </button>
        {error && <p style={styles.errorMessage}>{error}</p>}
        {resetPasswordMessage && <p style={styles.successMessage}>{resetPasswordMessage}</p>}
      </div>
    </div>
  );
};

const styles = {
  // ... (include all the styles related to the modal here)
  modal: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    position: 'relative',
  },
  modalTitle: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    fontSize: '24px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
  },
  modalContent: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    position: 'relative',
    width: '300px',
  }, 
  googleSignInContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  divider: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    margin: '20px 0',
  },
  dividerText: {
    padding: '0 10px',
    backgroundColor: 'white',
    position: 'relative',
    zIndex: 1,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    width: '100%',
    padding: '10px',
    margin: '5px 0',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  submitButton: {
    width: '100%',
    padding: '10px',
    margin: '10px 0',
    backgroundColor: '#4285F4',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  signUpContainer: {
    textAlign: 'center',
    marginTop: '15px',
  },
  signUpLink: {
    color: '#4285F4',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  errorMessage: {
    color: 'red',
    marginTop: '10px',
  },
  forgotPasswordButton: {
    background: 'none',
    border: 'none',
    color: '#4285F4',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginTop: '10px',
  },
  successMessage: {
    color: 'green',
    marginTop: '10px',
  },
};
export default SignInModal;
import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'
import './ImageGallery.css';

const ImageGallery = ({ images, placeName, onImageClick, photoEndpoint }) => {
  const [imageUrls, setImageUrls] = useState({});
  const [imageErrors, setImageErrors] = useState({});
  const [loadedImages, setLoadedImages] = useState({});

  const handleImageLoad = (index) => {
    setLoadedImages(prev => ({...prev, [index]: true}));
  };


  useEffect(() => {
    const fetchImageUrls = async () => {
      const urls = {};
      const fetchPromises = images.map(async (photoReference, index) => {
        try {
          const response = await fetch(`${photoEndpoint}?photo_reference=${photoReference}&maxwidth=400`);
          const data = await response.json();
          urls[index] = data.url;
        } catch (error) {
          console.error(`Error fetching image URL for index ${index}:`, error);
        }
      });

      await Promise.all(fetchPromises);
      setImageUrls(urls);
    };

    fetchImageUrls();
  }, [images, photoEndpoint]);

  const handleImageError = (index, error) => {
    console.error(`Error loading image ${index}:`, error);
    console.log('Image URL:', `${photoEndpoint}?photo_reference=${images[index]}&maxwidth=400`);
    setImageErrors(prev => ({ ...prev, [index]: true }));
  };
  return (
    <div className="image-gallery">
    {images.map((photoReference, index) => (
      <div key={index} className="image-container">
        <LazyLoadImage
          src={imageUrls[index]}
          alt={`${placeName} ${index + 1}`}
          effect="blur"
          className={`gallery-image ${loadedImages[index] ? 'loaded' : ''}`}
          onClick={() => onImageClick(photoReference)}
          onLoad={() => handleImageLoad(index)}
          placeholderSrc="/path/to/placeholder.jpg" // Add a placeholder image
        />
        {!loadedImages[index] && <div className="image-placeholder" />}
      </div>
    ))}
  </div>
  );
};

export default ImageGallery;
import React, { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase';
import { signOut } from 'firebase/auth';
import './App.css';
import ButtonComponent from './ButtonComponent';
import Header from './Header';
import SignInModal from './SignInModal';

function App() {
  const [user, loading] = useAuthState(auth);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [showFavorites, setShowFavorites] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [shouldResetSearch, setShouldResetSearch] = useState(false);

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        setShouldResetSearch(true);
        setShowFavorites(false);
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };

  const toggleFavorites = () => {
    setShowFavorites(!showFavorites);
  };

  const toggleHistory = () => {
    setShowHistory(!showHistory);
  };

  return (
    <div className="App">
      <Header 
        user={user}
        onSignInClick={() => setShowSignInModal(true)}
        onSignOut={handleSignOut}
        onToggleFavorites={toggleFavorites}
        onToggleHistory={toggleHistory}
      />
      <div>
        <h1 className="text-2xl lg:text-5xl mb-10 text-right">
          Discover New Foods Around You
        </h1>
      </div>
      <ButtonComponent 
        user={user}
        showFavorites={showFavorites}
        setShowFavorites={setShowFavorites}
        showHistory={showHistory}
        setShowHistory={setShowHistory}
        shouldResetSearch={shouldResetSearch}
        setShouldResetSearch={setShouldResetSearch}
      />
      {showSignInModal && (
        <SignInModal
          onClose={() => setShowSignInModal(false)}
        />
      )}
    </div>
  );
}

export default App;
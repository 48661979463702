import React, { useRef, useEffect, useState } from 'react';
import './ButtonComponent.css';
import noImagePlaceholder from './images/no-image-placeholder.png';

const HistoryList = ({ history, onClose, onNavigate, onClearHistory, onAddToFavorites, favorites, user }) => {
  const modalRef = useRef();  
  const isPlaceFavorite = (placeId) => {
        return favorites.some(favorite => favorite.place_id === placeId);
      };

      useEffect(() => {
        const handleClickOutside = (event) => {
          if (modalRef.current && !modalRef.current.contains(event.target)) {
            onClose();
          }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const handleNavigate = (place) => {
      console.log('Navigating to place:', place); // Debug log
      if (place.geometry && place.geometry.location) {
          onNavigate(place);
      } else if (place.latitude && place.longitude) {
          onNavigate({
              geometry: {
                  location: {
                      lat: place.latitude,
                      lng: place.longitude
                  }
              }
          });
      } else {
          console.error('Unable to navigate: location data missing', place);
      }
  };

    return (
    <div className="modal-overlay">
      <div className="modal" ref={modalRef}>
        <div className="modal-content">
          <button onClick={onClose} className="close-button">&times;</button>
          <h2 className="modal-title">Recent Places</h2>
          {history.length === 0 ? (
            <p>No places in history yet.</p>
          ) : (
            <>
            <ul className="history-list">
              {history.map((place, index) => (
                <li key={`${place.place_id}-${index}`} className="history-item">
                  <img 
                    src={place.photoURL || noImagePlaceholder}
                    alt={place.name || 'Place'}
                    className="history-image"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = noImagePlaceholder;
                    }}
                  />
                  <div className="history-info">
                    <h3>{place.name}</h3>
                    <p>{place.vicinity}</p>
                  </div>
                  <div className="history-buttons">
                      <button 
                        onClick={() => handleNavigate(place)}
                        className="button-17 navigate-button"
                      >
                        Navigate
                      </button>
                      <button 
                        onClick={() => onAddToFavorites(place)}
                        className={`button-17 favorite-button ${isPlaceFavorite(place.place_id) ? 'favorite-active' : ''}`}
                        disabled={ isPlaceFavorite(place.place_id)}
                      >
                        {isPlaceFavorite(place.place_id) ? 'Favorited' : 'Favorite'}
                      </button>
                    </div>
                </li>
              ))}
            </ul>
            <button 
            onClick={onClearHistory}
            className="button-17 clear-history-button"
          >
            Clear History
          </button>
          </>
          )}
        </div>
      </div>
    </div>
  );
};

export default HistoryList;
import React, { useRef, useEffect } from 'react';

const AutocompleteInput = ({ value, onChange, onSelect }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (!window.google) {
      console.error(' from autocomplete js Google Maps JavaScript API not loaded');
      return;
    }

    const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
      types: ['address']
    });

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (place.formatted_address) {
        onChange(place.formatted_address);
        onSelect({
          address: place.formatted_address,
          location: {
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng()
          }
        });
      }
    });

    return () => {
      // Cleanup if needed
    };
  }, [onChange, onSelect]);

  return (
    <input
      ref={inputRef}
      type="text"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder="Enter address"
    />
  );
};

export default AutocompleteInput;
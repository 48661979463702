// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.placeholder-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }
  
  .placeholder-image {
    width: 150px;
    height: 150px;
    background: #f6f7f8;
    position: relative;
    overflow: hidden;
  }
  
  .placeholder-image::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
  }
  
  @keyframes load {
    from {
      left: -150px;
    }
    to {
      left: 100%;
    }
  }`, "",{"version":3,"sources":["webpack://./src/ImagePlaceholder.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,SAAS;IACT,gBAAgB;EAClB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,cAAc;IACd,kBAAkB;IAClB,YAAY;IACZ,MAAM;IACN,YAAY;IACZ,YAAY;IACZ,oFAAoF;IACpF,0DAA0D;EAC5D;;EAEA;IACE;MACE,YAAY;IACd;IACA;MACE,UAAU;IACZ;EACF","sourcesContent":[".placeholder-content {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    gap: 10px;\n    margin-top: 20px;\n  }\n  \n  .placeholder-image {\n    width: 150px;\n    height: 150px;\n    background: #f6f7f8;\n    position: relative;\n    overflow: hidden;\n  }\n  \n  .placeholder-image::before {\n    content: '';\n    display: block;\n    position: absolute;\n    left: -150px;\n    top: 0;\n    height: 100%;\n    width: 150px;\n    background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);\n    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;\n  }\n  \n  @keyframes load {\n    from {\n      left: -150px;\n    }\n    to {\n      left: 100%;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React,  { useState } from 'react';
import './ButtonComponent.css';
import noProfilePic from './images/no-profile-pic.png';
import Logo from './Logo';


const Header = ({ user, onSignInClick, onSignOut, onToggleFavorites  }) => {
  const [showBugForm, setShowBugForm] = useState(false);
  const [bugReport, setBugReport] = useState({ title: '', description: '' });

  const getUserDisplayInfo = (user) => {
    if (user.displayName) {
      return user.displayName;
    } else if (user.email) {
      // If email is available, return the part before the @ symbol
      return user.email.split('@')[0];
    } else {
      return 'Guest';
    }
  };

  const handleBugIconClick = () => {
    setShowBugForm(true);
  };

  const handleBugReportChange = (e) => {
    const { name, value } = e.target;
    setBugReport(prev => ({ ...prev, [name]: value }));
  };

  const handleBugReportSubmit = (e) => {
    e.preventDefault();
    const emailBody = `Bug Report:
Title: ${bugReport.title}
Description: ${bugReport.description}`;

    window.location.href = `mailto:support@lsjabcllc.com?subject=Bug Report: ${bugReport.title}&body=${encodeURIComponent(emailBody)}`;
    
    setShowBugForm(false);
    setBugReport({ title: '', description: '' });
  };
  return (
    <div className="header">
      
       <div className="logo-container">
          <Logo width={150} height={40} />  {/* Adjust size as needed */}
        </div>
        <div className="header-right">
      <div className="user-info">
      {user && (
          <>
          <img 
          src={user.photoURL || noProfilePic} 
          alt={getUserDisplayInfo(user)}
          className="user-image"
          onError={(e) => {
            e.target.onerror = null; // Prevent infinite loop if defaultUserAvatar also fails to load
            e.target.src = noProfilePic;
          }} />
           <span className="user-name" title={user.email}>
            {getUserDisplayInfo(user)}
          </span>
          </> 
        )}
          </div>
          <div className="header-buttons">
          {user ? (
          <button onClick={onSignOut} className="button-17 sign-out-button">Sign Out</button>
       
      ) : (
        <button onClick={onSignInClick} className="button-17 sign-in-button">
          Sign In
        </button>
      )}
      
      <button onClick={onToggleFavorites} className="button-17 favorites-button">
        Favorites
      </button>
      
      </div>
    </div>
    <div className="bug-icon" onClick={handleBugIconClick}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M8 2l1.88 1.88"></path>
          <path d="M14.12 3.88L16 2"></path>
          <path d="M9 7.13v-1a3.003 3.003 0 1 1 6 0v1"></path>
          <path d="M12 20c-3.3 0-6-2.7-6-6v-3a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v3c0 3.3-2.7 6-6 6"></path>
          <path d="M12 20v-9"></path>
          <path d="M6.53 9C4.6 8.8 3 7.1 3 5"></path>
          <path d="M6 13H2"></path>
          <path d="M3 21c0-2.1 1.7-3.9 3.8-4"></path>
          <path d="M20.97 5c0 2.1-1.6 3.8-3.5 4"></path>
          <path d="M22 13h-4"></path>
          <path d="M17.2 17c2.1.1 3.8 1.9 3.8 4"></path>
        </svg>
      </div>
      {showBugForm && (
        <div className="bug-form-modal">
          <div className="bug-form-content">
            <h2>Report a Bug</h2>
            <form onSubmit={handleBugReportSubmit}>
              <input
                type="text"
                name="title"
                value={bugReport.title}
                onChange={handleBugReportChange}
                placeholder="Bug Title"
                required
              />
              <textarea
                name="description"
                value={bugReport.description}
                onChange={handleBugReportChange}
                placeholder="Describe the bug..."
                required
              ></textarea>
              <div className="bug-form-buttons">
                <button type="submit">Submit</button>
                <button type="button" onClick={() => setShowBugForm(false)}>Cancel</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

// const styles = {
//   // ... (include all the styles related to the header here)
//   header: {
//     display: 'flex',
//     justifyContent: 'flex-end',
//     padding: '10px 20px',
//     backgroundColor: '#f0f0f0',
//   },
  
//   userInfo: {
//     display: 'flex',
//     alignItems: 'center',
//   },
//   userImage: {
//     width: '30px',
//     height: '30px',
//     borderRadius: '50%',
//     marginRight: '10px',
//   },
  
//   userName: {
//     marginRight: '10px',
//   },
//   signInButton: {
//     padding: '8px 16px',
//     backgroundColor: '#4285F4',
//     color: 'white',
//     border: 'none',
//     borderRadius: '4px',
//     cursor: 'pointer',
//     fontSize: '14px',
//   },
//   // signOutButton: {
//   //   marginLeft: '10px',
//   //   padding: '5px 10px',
//   //   backgroundColor: '#f44336',
//   //   color: 'white',
//   //   border: 'none',
//   //   borderRadius: '4px',
//   //   cursor: 'pointer',
//   // },
//   signOutButton: {
//     padding: '8px 16px',
//     backgroundColor: '#f44336',
//     color: 'white',
//     border: 'none',
//     borderRadius: '4px',
//     cursor: 'pointer',
//     fontSize: '14px',
//   },
// };

export default Header;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }
  .image-container {
    position: relative;
    width: 150px;
    height: 150px;
  }
  
  .gallery-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    opacity: 0;
    transition: opacity 0.5s ease-in;
    border-radius: 8px;
  }
  
  .gallery-image.loaded {
    opacity: 1;
  }
  
  .image-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
    animation: pulse 1.5s infinite;
    border-radius: 8px;
  }
  
  @keyframes pulse {
    0% {
      background-color: #f0f0f0;
    }
    50% {
      background-color: #e0e0e0;
    }
    100% {
      background-color: #f0f0f0;
    }
  }`, "",{"version":3,"sources":["webpack://./src/ImageGallery.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,SAAS;IACT,gBAAgB;EAClB;EACA;IACE,kBAAkB;IAClB,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,iBAAiB;IACjB,UAAU;IACV,gCAAgC;IAChC,kBAAkB;EACpB;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,8BAA8B;IAC9B,kBAAkB;EACpB;;EAEA;IACE;MACE,yBAAyB;IAC3B;IACA;MACE,yBAAyB;IAC3B;IACA;MACE,yBAAyB;IAC3B;EACF","sourcesContent":[".image-gallery {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    gap: 10px;\n    margin-top: 20px;\n  }\n  .image-container {\n    position: relative;\n    width: 150px;\n    height: 150px;\n  }\n  \n  .gallery-image {\n    width: 150px;\n    height: 150px;\n    object-fit: cover;\n    opacity: 0;\n    transition: opacity 0.5s ease-in;\n    border-radius: 8px;\n  }\n  \n  .gallery-image.loaded {\n    opacity: 1;\n  }\n  \n  .image-placeholder {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: #f0f0f0;\n    animation: pulse 1.5s infinite;\n    border-radius: 8px;\n  }\n  \n  @keyframes pulse {\n    0% {\n      background-color: #f0f0f0;\n    }\n    50% {\n      background-color: #e0e0e0;\n    }\n    100% {\n      background-color: #f0f0f0;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

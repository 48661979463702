import React, { useState, useEffect, useCallback, useRef } from 'react';
import debounce from 'lodash/debounce';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase';
import { signOut } from 'firebase/auth';
import { 
  fetchRandomPlace, 
  fetchGalleryImages, 
  geocodeAddress, 
  fetchPhoto, 
  initializeAutocomplete,
  addFavoritePlace,
  getUserFavoritePlaces,
  removeFavoritePlace } from './api';
import AutocompleteInput from './AutocompleteInput';
import './Loader.css'; 
import GoogleSignIn from './GoogleSignIn';
import Header from './Header';
import SignInModal from './SignInModal';
import './ButtonComponent.css';
import ImageGallery from './ImageGallery';
import Placeholder from './ImagePlaceholderComponent';
import './ImagePlaceholder.css';
import axios from 'axios';
import FavoritesList from './FavoriteList';
import HistoryList from './HistoryList';

const API_BASE_URL ='https://eat-app-api-middleware-b2g9k.ondigitalocean.app';
export const ENDPOINTS = {
  PLACES: `${API_BASE_URL}/api/places`,
  DETAILS: `${API_BASE_URL}/api/details`,
  GEOCODE: `${API_BASE_URL}/api/geocode`,
  PHOTO: `${API_BASE_URL}/api/photo`
};

const ButtonComponent = ({   
  user, 
  showFavorites, 
  setShowFavorites, 
  showHistory, 
  setShowHistory,
  shouldResetSearch, 
  setShouldResetSearch}) => {
  const [place, setPlace] = useState(null);
  const [error, setError] = useState(null);
  const [distance,setDistance] = useState(5)
  const [galleryImages, setGalleryImages] = useState([]);
  const [address, setAddress] = useState('');
const [useCurrentLocation, setUseCurrentLocation] = useState(true);
const [location, setLocation] = useState(null);
const [isLoading, setIsLoading] = useState(false);
const [expandedImageUrl, setExpandedImageUrl] = useState(null);
//const [user, setUser] = useState(null);
const [showSignInModal, setShowSignInModal] = useState(false);
const [isGoogleLoaded, setIsGoogleLoaded] = useState(false);
const [mainImageUrl, setMainImageUrl] = useState(null);
  const [mainImageError, setMainImageError] = useState(false);
  const [imageCache, setImageCache] = useState({});
  const [isMainImageLoading, setIsMainImageLoading] = useState(false);
  const [mainImageDimensions, setMainImageDimensions] = useState({ width: '100%', height: '200px' });
  const [isImagesLoading, setIsImagesLoading] = useState(false);
  const [mainImageLoaded, setMainImageLoaded] = useState(false);
  const [favorites, setFavorites] = useState([]);
//  const [showFavorites, setShowFavorites] = useState(false);
  const [notification, setNotification] = useState(null);
  const [showSignInPrompt, setShowSignInPrompt] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false)
  const [history, setHistory] = useState([]);
  const [clickCount, setClickCount] = useState(0);
  const [authUser] = useAuthState(auth);
  const [nearbyPlaces, setNearbyPlaces] = useState([]);
  const [shownPlaces, setShownPlaces] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [debouncedAddress, setDebouncedAddress] = useState('');
  const [sortedFavorites, setSortedFavorites] = useState([]);
  

useEffect(() => {
  if (shouldResetSearch) {
    resetSearch();
    setShouldResetSearch(false);
  }
}, [shouldResetSearch, setShouldResetSearch]);

const resetSearch = () => {
  setAddress('');
  setUseCurrentLocation(true);
  setLocation(null);
  setPlace(null);
  setGalleryImages([]);
  setMainImageUrl(null);
  setExpandedImageUrl(null);
  setFavorites([]);
  setIsFavorite(false);
  setDistance(5);
  setError(null);
  showNotification("Start a new search!");
};

const fetchImageUrl = useCallback(async (photoReference, maxwidth = 400) => {
  if (imageCache[photoReference]) {
    return imageCache[photoReference];
  }

  try {
    const response = await axios.get(`${ENDPOINTS.PHOTO}?photo_reference=${photoReference}&maxwidth=${maxwidth}`);
    const url = response.data.url;
    setImageCache(prev => ({ ...prev, [photoReference]: url }));
    return url;
  } catch (error) {
    console.error('Error fetching image URL:', error);
    return null;
  }
  finally {
    setIsMainImageLoading(false);
  }
}, [imageCache]);

const handleMainImageLoad = () => {
  setMainImageLoaded(true);}

useEffect(() => {
  const fetchMainImageUrl = async () => {
    if (place && place.photos && place.photos[0]) {
      const url = await fetchImageUrl(place.photos[0].photo_reference);
      setMainImageUrl(url);
    }

  };

  if (place) {
    fetchMainImageUrl();
  }
}, [place, fetchImageUrl]);



const fetchNearbyPlaces = useCallback(async (location) => {
  if (!location) return;
  
  try {
    setIsLoading(true);
    const radius = distance * 1609.34; // Convert miles to meters
    const response = await axios.get(ENDPOINTS.PLACES, {
      params: {
        lat: location.lat,
        lng: location.lng,
        radius: radius
      }
    });
    setNearbyPlaces(response.data.results);
    setShownPlaces([]);
    setIsLoading(false);
  } catch (error) {
    console.error('Error fetching nearby places:', error);
    //setError('Error fetching nearby places');
    setIsLoading(false);
  }
}, [distance]);

const debouncedFetchPlaces = useRef(
  debounce(fetchNearbyPlaces, 500)
).current;

useEffect(() => {
  if (currentLocation) {
    debouncedFetchPlaces(currentLocation);
  }
}, [currentLocation, debouncedFetchPlaces]);


const getRandomPlace = useCallback(() => {
  const availablePlaces = nearbyPlaces.filter(place => !shownPlaces.includes(place.place_id));
  console.log('shown places',shownPlaces)
  if (availablePlaces.length === 0) {
    // All places have been shown, reset the list
    setShownPlaces([]);showNotification("You've seen all nearby places! Try increasing your distance!");
    return nearbyPlaces[Math.floor(Math.random() * nearbyPlaces.length)];
  }
  
  return availablePlaces[Math.floor(Math.random() * availablePlaces.length)];
}, [nearbyPlaces, shownPlaces]);

const updateCurrentLocation = useCallback(async (addressToGeocode) => {
  if (useCurrentLocation) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
        },
        handleLocationError
      );
    } else {
      setError('Geolocation is not supported by this browser.');
    }
  }
    else if (addressToGeocode) {
      try {
        const coordinates = await geocodeAddress(addressToGeocode);
        setCurrentLocation(coordinates);
      } catch (error) {
        setError('Hmm. We couldn\'t find anything. Please try entering your zip code or using your current location');
      }
    }
  }, [useCurrentLocation]);

//   } else {
//     const sanitizedAddress = sanitizeAddress(address);
//     if (sanitizedAddress) {
//       try {
//         const coordinates = await geocodeAddress(sanitizedAddress);
//         setCurrentLocation(coordinates);
//       } catch (error) {
//         setError('Hmm. We couldn\'t find anything. Please try entering your zip code or using your current location');
//       }
//     } else {
//       setError('Please enter an address.');
//     }
//   }
// }, [useCurrentLocation, address]);

useEffect(() => {
  updateCurrentLocation();
}, [useCurrentLocation,  debouncedAddress, updateCurrentLocation]);

const debouncedUpdateLocation = useRef(
  debounce((address) => updateCurrentLocation(address), 500)
).current;

useEffect(() => {
  if (currentLocation) {
    debouncedFetchPlaces(currentLocation);
  }
}, [currentLocation, debouncedFetchPlaces]);

const handleAddressChange = (newAddress) => {
  setAddress(newAddress);
  if (!useCurrentLocation) {
    debouncedUpdateLocation(newAddress);
  }
};

const debouncedHandleAddressChange = useRef(
  debounce(handleAddressChange, 300)
).current;

const handleSelect = (selectedPlace) => {
  setAddress(selectedPlace.address);
  setCurrentLocation(selectedPlace.location);
};

const handleClick = async () => {
  setClickCount(prevCount => prevCount + 1);
    
  if (!user && clickCount + 1 >= 5) {
    setShowSignInPrompt(true);
    showNotification("Please sign in to continue searching!");
    return;
  }
  if (nearbyPlaces.length > 0) {
    const randomPlace = getRandomPlace();
    setPlace(randomPlace);
    setShownPlaces(prev => [...prev, randomPlace.place_id]);
  setIsLoading(true);
    setIsImagesLoading(true);
    setMainImageLoaded(false);
    setMainImageError(false);
    setMainImageUrl(null);
    



  const sanitizedAddress = sanitizeAddress(address);
  setAddress(sanitizedAddress);

  if (useCurrentLocation) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(fetchPlace, handleLocationError);
    } else {
      setError('Geolocation is not supported by this browser.');
    }
  } else {
    if (sanitizedAddress) {
      try {
        const coordinates = await geocodeAddress(sanitizedAddress);
        fetchPlace({ coords: coordinates });
        console.log(coordinates)


      } catch (error) {
        setError('Hmm. We couldn\'t find anything. Please try entering your zip code or using your current location');
      }
    } else {
      setError('Please enter an address.');
    }
    
  }
  
}
else {
  updateCurrentLocation();
}
};

const fetchPlace = async (position) => {
  const userLocation = {
    lat: position.coords.latitude,
    lng: position.coords.longitude,
  };


    try {
      setMainImageUrl(null);
      setGalleryImages([]);
        const radius = distance * 1609.34;
      const randomPlace = await fetchRandomPlace(userLocation, radius);
    
      setPlace(randomPlace);
      setError(null)
      console.log("Random place fetched:", randomPlace); // Debugging step
  

      if (randomPlace.geometry && randomPlace.geometry.location) {
        setLocation({
          latitude: randomPlace.geometry.location.lat,
          longitude: randomPlace.geometry.location.lng
        });
      }
      if (randomPlace.photos && randomPlace.photos.length > 0) {
        const photoReference = randomPlace.photos[0].photo_reference;
        randomPlace.photoURL = await fetchPhoto(photoReference);
      }

      setPlace(randomPlace);
      setError(null);

      setHistory(prevHistory => {
        const updatedHistory = [randomPlace, ...prevHistory.slice(0, 19)];
        localStorage.setItem('placeHistory', JSON.stringify(updatedHistory));
        return updatedHistory;
      });

      setIsLoading(false);
      if (randomPlace.place_id) {
        console.log('fetching gallery images for place: ', randomPlace.name, randomPlace.place_id)
        console.log('random place: ', randomPlace)
        const images = await fetchGalleryImages(randomPlace.place_id);
        console.log('feteched gallery images:',images)
        setGalleryImages(images);
       // fetchGalleryImages(randomPlace.place_id);
      }
    } catch (error) {
      console.error('Error fetching random place:', error);
      setError('Error fetching random place');
    } finally{
      setIsLoading(false)
      setIsImagesLoading(false);
    }

}

useEffect(() => {
  const storedHistory = localStorage.getItem('placeHistory');
  if (storedHistory) {
    setHistory(JSON.parse(storedHistory));
  }
}, []);

const clearHistory = () => {
  setHistory([]);
  localStorage.removeItem('placeHistory');
  showNotification('History cleared');
};

// Automatic history clearing after 30 days
useEffect(() => {
  const lastClearDate = localStorage.getItem('lastHistoryClearDate');
  const currentDate = new Date().toISOString();

  if (!lastClearDate || new Date(currentDate) - new Date(lastClearDate) > 30 * 24 * 60 * 60 * 1000) {
    clearHistory();
    localStorage.setItem('lastHistoryClearDate', currentDate);
  }
}, []);

const toggleHistory = () => {
  setShowHistory(!showHistory);
};

const sanitizeAddress = (address) => {
  return address.replace(/,/g, '');
};

const handleLocationError = (geoError) => {
  console.error('Error getting location:', geoError);
  setError('Error getting location');
};

const handleNavigate = (place) => {
  console.log('handleNavigate called with:', place); // Debug log
  if (place) {
    let lat, lng;
    if (place.geometry && place.geometry.location) {
      lat = place.geometry.location.lat;
      lng = place.geometry.location.lng;
    } else if (place.latitude && place.longitude) {
      lat = place.latitude;
      lng = place.longitude;
    } else {
      console.error('Unable to find location data for this place:', place);
      return;
    }

    // Check if lat and lng are functions (Google Maps API sometimes returns them as functions)
    lat = typeof lat === 'function' ? lat() : lat;
    lng = typeof lng === 'function' ? lng() : lng;

    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
    window.open(googleMapsUrl, '_blank');
  } else {
    console.error('No place selected for navigation');
  }
};

  const handleDistanceChange = (newDistance) => {
    setDistance(newDistance)
  }

  // const handleSelect = (selectedPlace) => {
  //   console.log('handleSelect called with:', selectedPlace);
  //   setAddress(selectedPlace.address);
  //   setLocation(selectedPlace.location);
  // };
  
  // const handleAddressChange = (newAddress) => {
  //   console.log('handleAddressChange called with:', newAddress);
  //   setAddress(newAddress);
  // };
  
  useEffect(() => {
    console.log('Address state updated:', address);
  }, [address]);
  
  useEffect(() => {
    if (window.google && window.google.maps && window.google.maps.places) {
      setIsGoogleLoaded(true);
      console.log("Google Maps API Key:", process.env.API_KEY);
    } else {
      console.error('from button component Google Maps Places API not loaded');
    }
  }, []);

  useEffect(()=> {
    console.log('Gallery images:', galleryImages)
  }, [galleryImages])

  const handleImageClick = async (photoReference) => {
    const url = await fetchImageUrl(photoReference, 800);
    setExpandedImageUrl(url);
  };

  const handleCloseExpandedImage = () => {
    setExpandedImageUrl(null);
  };


  // const handleSignIn = (userData) => {
  //   setUser(userData);
  //   setShowSignInModal(false);
  // };

  
  

  const handleSignUp = () => {
    // Implement sign up functionality
    console.log('Sign up clicked');
  };


  useEffect(() => {
    // Load favorites from localStorage when the component mounts
    const storedFavorites = localStorage.getItem('favorites');
    if (storedFavorites) {
      setFavorites(JSON.parse(storedFavorites));
    }
  }, []);

  useEffect(() => {
    // Save favorites to localStorage whenever it changes
    localStorage.setItem('favorites', JSON.stringify(favorites));
  }, [favorites]);

  const showNotification = (message) => {
    console.log("Showing notification:", message); // Debug log
    setNotification(message);
    setTimeout(() => {
      console.log("Clearing notification"); // Debug log
      setNotification(null);
    }, 3000);
  };

  useEffect(() => {
    if (user) {
      fetchFavorites();
    }else {
      // Clear favorites when user logs out
      setFavorites([]);
      setIsFavorite(false);
    }
  }, [user]);

  const fetchFavorites = async () => {
    if (user) {
      try {
        const userFavorites = await getUserFavoritePlaces(user.uid);
        setFavorites(userFavorites);
      } catch (error) {
        console.error('Error fetching favorites:', error);
      }
    }
  };

  useEffect(() => {
    if (user && place && favorites.some(fav => fav.place_id === place.place_id)) {
      setIsFavorite(true);
    } else {
      setIsFavorite(false);
    }
  }, [place, favorites, user]);

  // const handleAddToFavorites = () => {
  //   console.log("handleAddToFavorites called");
  //   if (!user) {
  //     setShowSignInPrompt(true);
  //     showNotification("Please sign in to add favorites!");
  //     return;
  //   }
  //   if (place && !favorites.some(fav => fav.place_id === place.place_id)) {
  //     const newFavorite = {
  //       place_id: place.place_id,
  //       name: place.name,
  //       vicinity: place.vicinity,
  //       photoReference: place.photos && place.photos.length > 0 ? place.photos[0].photo_reference : null
  //     };
  //     setFavorites([...favorites, newFavorite]);
  //     showNotification(`${place.name} added to favorites!`);
  //   } else {
  //     showNotification(`${place.name} is already in favorites!`);
  //   }
  // };

  const handleAddToFavorites = async (placeToAdd) => {
    const placeToFavorite = placeToAdd || place; // Use passed place or current place state

     if (!user || !user.uid || !placeToFavorite || !placeToFavorite.place_id) {
      setShowSignInPrompt(true);
    showNotification("Please sign in to add favorites!");
      console.log('Missing user or place information');
      return;
    }
    try {
      const isAlreadyFavorite = favorites.some(fav => fav.place_id === placeToFavorite.place_id);
      if (isAlreadyFavorite) {
        showNotification(`${placeToFavorite.name} is already in your favorites!`);
        return;
      }
      let photoReference = null;
      let firebaseUrl = null;
      if (placeToFavorite.photos && placeToFavorite.photos.length > 0) {
        photoReference = placeToFavorite.photos[0].photo_reference;
        firebaseUrl = await fetchPhoto(photoReference);
      }
      console.log('Adding to favorites:', { userId: user.uid, placeId: placeToFavorite.place_id, photoReference, firebaseUrl });
      await addFavoritePlace(user.uid, placeToFavorite.place_id, placeToFavorite.name, placeToFavorite.vicinity, photoReference, firebaseUrl);
      showNotification(`${placeToFavorite.name} added to favorites!`);
      setIsFavorite(true);
      fetchFavorites(); // Refresh the favorites list
    } catch (error) {
      console.error('Error adding to favorites:', error);
      showNotification('Error adding to favorites');
    }
  };
  const handleSignInPromptClose = () => {
    setShowSignInPrompt(false);
  };
  
  const handleSignInClick = () => {
    setShowSignInPrompt(false);
    setShowSignInModal(true);
    setShowFavorites(false);
    setShowHistory(false);
  };

  // const handleRemoveFromFavorites = (placeId) => {
  //   setFavorites(favorites.filter(fav => fav.place_id !== placeId));
  // };

  // const handleRemoveFromFavorites = async () => {
  //   try {
  //     await removeFavoritePlace(user.uid, place.place_id);
  //     setIsFavorite(false);
  //     fetchFavorites(); // Refresh the favorites list
  //     console.log('removed from favorites called')
  //   } catch (error) {
  //     console.error('Error removing from favorites:', error);
  //   }
  // };
  // const toggleFavorites = () => {
  //   setShowFavorites(!showFavorites);
  // };
  const handleRemoveFromFavorites = async (placeId) => {
    if (!user || !user.uid) {
      console.error('User not logged in');
      return;
    }
    if (!placeId) {
      console.error('No place_id provided for removal');
      return;
    }
    try {
      await removeFavoritePlace(user.uid, placeId);
      setIsFavorite(false);
      fetchFavorites(); // Refresh the favorites list
      console.log('Removed from favorites:', placeId);
      showNotification('Removed from favorites');
    } catch (error) {
      console.error('Error removing from favorites:', error);
      showNotification('Error removing from favorites');
    }
  };
  
  const handleSortFavorites = (sortOrder) => {
    let sorted = [...favorites];
    if (sortOrder === 'recent') {
      sorted.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    } else {
      // Default sorting (you can define your default sorting logic here)
      sorted.sort((a, b) => a.name.localeCompare(b.name));
    }
    setSortedFavorites(sorted);
  };

  useEffect(() => {
    // Initial sort when favorites are loaded or updated
    handleSortFavorites('default');
  }, [favorites])


  return (
    <div className="page-container">
         {notification && (
        <div className="notification">
          {notification}
        </div>
      )}
   
    <div className="container">
    <button onClick={() => setShowHistory(!showHistory)} className="button-17 history-button">
          History
        </button>
      <div className="input-container">
        <label>
          <input
            type="checkbox"
            checked={useCurrentLocation}
            onChange={() => setUseCurrentLocation(!useCurrentLocation)}
          />
          Use current location
        </label>
        {!useCurrentLocation && (
          <AutocompleteInput
            value={address}
            onChange={handleAddressChange}
            onSelect={handleSelect}
          />
        )}
      </div>
      {showSignInModal && (
        <SignInModal
          onClose={() => setShowSignInModal(false)}
         // onSignIn={handleSignIn}
        //  onGoogleSignIn={handleSignIn}
        />
      )}
  
      <div className="distance-buttons">
        {[5, 10, 20].map((dist) => (
          <button
            key={dist}
            className={`button-17 ${distance === dist ? 'button-17-selected' : ''}`}
            onClick={() => handleDistanceChange(dist)}
          >
            {dist} miles
          </button>
        ))}
      </div>
      
      <button 
        className={`button-62 ${isLoading ? 'button-62-disabled' : ''}`}
        onClick={handleClick} 
        disabled={isLoading}
      >
        {isLoading ? 'Fetching...' : "Let's eat"}
      </button>
      {isLoading && <div className="loader"></div>}
      {error && <p className="error-message">{error}</p>}
      {!isLoading && place && (
        <div className="place-info">
          <h3>{place.name}</h3>
          <p>{place.vicinity}</p>
          { mainImageUrl && !mainImageError ? (
            <img 
              src={mainImageUrl}
              alt={place.name} 
              className={`main-image ${mainImageLoaded ? 'loaded' : ''}`}
              onError={() => setMainImageError(true)} 
              onLoad={handleMainImageLoad}
            />
          ) : (
            <p>No image available</p>
          )}
  
          <div className="navigate-button-container">
            <button 
              className="button-17 navigate-button"
              onClick={() => handleNavigate(place)}
            >
              Navigate 
            </button>
            <button 
                className="button-17 favorite-button"
                onClick={() => handleAddToFavorites(place)}
                
              >
                Add to Favorites
              </button>
              
          </div>
          {/* Image Gallery */}

          { galleryImages.length > 0 && (
            <ImageGallery
              images={galleryImages}
              placeName={place.name}
              onImageClick={handleImageClick}
              photoEndpoint={ENDPOINTS.PHOTO}
            />
          )}


        </div>
      )}
      {expandedImageUrl && (
        <div className="expanded-image-overlay" onClick={handleCloseExpandedImage}>
          <div className="expanded-image-container">
            <img
              src={expandedImageUrl}
              alt="Expanded view"
              className="expanded-image"
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        </div>
      )}
          {showFavorites && (
        <FavoritesList 
          favorites={sortedFavorites}
          onRemove={handleRemoveFromFavorites}
          onClose={() => setShowFavorites(false)}
          onNavigate={handleNavigate}
          onSort={handleSortFavorites}
          photoEndpoint={ENDPOINTS.PHOTO}
        />
      )}
      {showSignInPrompt && (
        <div className="sign-in-prompt-overlay">
          <div className="sign-in-prompt">
            <p>Please sign in to continue!</p>
            <button className="button-17" onClick={handleSignInClick}>Sign In</button>
            <button className="button-17" onClick={handleSignInPromptClose}>Cancel</button>
          </div>
        </div>
      )}
      {showSignInModal && (
        <SignInModal
          onClose={() => setShowSignInModal(false)}
        />
      )}
        {showHistory && (
          <HistoryList 
            history={history}
            onClose={() => setShowHistory(false)}
            onNavigate={handleNavigate}
            onClearHistory={clearHistory}
            onAddToFavorites={handleAddToFavorites}
            favorites={favorites}
            user={user}
          />
        )}
    </div>
  </div>
);
};

export default ButtonComponent;
